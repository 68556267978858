<template>
  <b-row>
    <b-col cols="12">
      <b-card class="m-auto w-90 shadow">
        <template>
          <div>
            <vue-apex-charts
              width="100%"
              height="300"
              type="line"
              :options="options"
              :series="series"
              title="Monthly Graph"
            />
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import { core } from '@/config/pluginInit'
import VueApexCharts from 'vue-apexcharts'
import { DashboardService } from '@/services'

export default {
  name: 'ChartComponent',
  middleware: ['auth'],
  components: {
    VueApexCharts
  },
  data () {
    return {
      month: '',
      data: [],
      series: [{ data: [] }],
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        title: {
          text: 'Product Category graph for the month of ' + this.month,
          align: 'center',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'Muli',
            color: '#263238'
          }
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            rotate: -50,
            style: {
              marginLeft: '20px'
            }
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: '#06C270',
          width: 1,
          dashArray: 0
        },
        markers: {
          size: 4,
          colors: '#06C270',
          hover: {
            size: 6,
            colors: '#06C270'
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: () => 'Month of ' + this.month
            }
          }
        }
      }
    }
  },
  mounted () {
    core.index()
    this.fetchDataCategory()
  },
  methods: {
    async fetchDataCategory () {
      await DashboardService.getPerformingCategories(
        `page=${this.page_dcp}`
      ).then(({ data }) => {
        this.month = data?.month
        this.series = [{ data: data.data.total_moveout }]
        this.options.title.text = `Product Category graph for the month of ${this.month}`
        this.updateXAxis(data.data.categories)
      })
    },

    async updateXAxis (data) {
      this.options = {
        ...this.options,
        xaxis: {
          categories: data
        }
      }
    }
  }
}
</script>
