<template>
  <b-container
    class="mb-5"
    style="position: relative"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-tabs no-body>
          <b-tab
            title="Monthly Moveout by dealers"
            active
          >
            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="12"
                style="z-index: 20"
              >
                <iq-card>
                  <template v-slot:body>
                    <div>
                      <h6 class="mb-2">
                        Top performing dealers for the month of {{ month }}
                      </h6>
                      <b-table
                        hover
                        :items="top_performing_dealers"
                        :fields="tableShowProductDetails.fields"
                        class="text-center"
                      >
                        <template #cell(quantity)="value">
                          {{ value.item.quantity }}
                        </template>
                      </b-table>
                      <button
                        v-if="seeMoreDealer"
                        class="btn btn-link"
                        @click="viewMoreDealer"
                      >
                        See more
                      </button>
                    </div>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Monthly Moveout by DCP">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="12"
                style="z-index: 20"
              >
                <iq-card class="w-100">
                  <template v-slot:body>
                    <h6 class="mb-2">
                      Top performing dcp for the month of {{ month }}
                    </h6>
                    <div>
                      <b-table
                        striped
                        hover
                        responsive
                        :items="top_performing_dcp"
                        :fields="tableShowProductDetails.field_dcp"
                        class="text-center"
                      >
                        <template #cell(quantity)="value">
                          {{ value.item.quantity }}
                        </template>
                      </b-table>
                    </div>
                    <button
                      v-if="seeMoreDcp"
                      class="btn btn-link"
                      @click="viewMoreDcp"
                    >
                      See more
                    </button>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col
        v-if="user.role !== 'marketing-officer'"
        cols="12"
        md="4"
      >
        <b-col
          v-for="({title, description, photos, files, active, created_at}, key) in data"
          :key="key"
          md="12"
          cols="12"
          class="mb-2"
        >

          <b-card
            :title="title"
            :img-src="imagePath(photos)"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2 shadow flex-fill"
          >
            <b-card-text class="min-h-100">
              {{ description }}
            </b-card-text>

            <div class="mb-4 d-flex flex-column justify-content-center align-items-start">
              <a
                v-for="(file, fileKey) in files "
                :key="fileKey"
                target="_blank"
                :href="file.path"
                class="py-2"
              >
                Click here to download
              </a>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <p class="m-0">
                Created at: {{ dateFormatter(created_at) }}
              </p>
            </div>
            <div
              v-if="photos.length > 0"
              class="d-flex justify-content-start align-items-center"
            >
              <b-button
                variant="link"
                class="ml-0 p-0 mt-2"
                @click="viewMorePhotos(photos)"
              >
                view more image
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      id="view-more-images"
      scrollable
      no-close-on-backdrop
      title="Announcement"
      class="mb-5"
      size="xl"
    >
      <VueSlickCarousel
        v-bind="settings"
      >
        <div
          v-for="(photo, key) in viewMorePhotosData"
          :key="key"
          class="slider-container"
        >
          <img
            :src="photo.path"
            class="slider-image"
            :alt="photo.name"
          >
        </div>

      </VueSlickCarousel>
    </b-modal>

    <ChartComponent />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DashboardService } from '@/services'
import formatter from '@/mixins/formatter'
import ChartComponent from '@/components/dashboard/Chart.component'
import store from '@/store'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'DashboardComponent',
  middleware: ['auth'],
  components: {
    ChartComponent,
    VueSlickCarousel
  },

  mixins: [formatter],
  data () {
    return {
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
      },
      user: store.getters['auth/user'],
      month: '',
      data: [],
      viewMorePhotosData: [],
      showAnnouncement: true,
      top_performing_dealers: [],
      top_performing_dcp: [],
      tableShowProductDetails: {
        fields: [
          {
            key: 'dealer_name'
          },
          {
            key: 'quantity_moveout'
          }
        ],
        field_dcp: [
          {
            key: 'dcp'
          },
          {
            key: 'quantity_moveout'
          }
        ]
      },
      page_dealer: 1,
      seeMoreDealer: false,
      page_dcp: 1,
      seeMoreDcp: false
    }
  },

  mounted () {
    core.index()
    this.fetchData()
    this.fetchDataDcp()
    this.getAnnouncement()
  },

  methods: {
    async fetchData () {
      await DashboardService.getPerformingDealers(
        `page=${this.page_dealer}`
      ).then(({ data }) => {
        if (data?.data !== 0) {
          this.top_performing_dealers = [
            ...this.top_performing_dealers,
            ...data.data
          ]
          this.seeMoreDealer = data.data.length >= 10
        }
        this.month = data.month
      })
    },

    async fetchDataDcp () {
      await DashboardService.getPerformingDcps(`page=${this.page_dcp}`).then(
        ({ data }) => {
          if (data?.data !== 0) {
            this.top_performing_dcp = [...this.top_performing_dcp, ...data.data]
            this.seeMoreDcp = data.data.length >= 10
          }
          this.month = data.month
        }
      )
    },
    async getAnnouncement () {
      await DashboardService.getAnnouncement('').then(({ data }) => {
        this.data = data
      })
    },

    viewMoreDealer () {
      this.page_dealer = this.page_dealer + 1
      this.fetchData()
    },

    viewMoreDcp () {
      this.page_dcp = this.page_dcp + 1
      this.fetchDataDcp()
    },

    toggleAnnouncement () {
      this.showAnnouncement = !this.showAnnouncement
    },
    imagePath (photos) {
      return photos?.[0]?.path || ''
    },

    viewMorePhotos (photos) {
      this.viewMorePhotosData = photos
      this.$bvModal.show('view-more-images')
    }
  }
}
</script>

<style lang="scss">
.card-img-top {
  cursor: pointer;
}

.slider-container {
  width: 100%;
  height: auto;
  overflow-X:hidden;
}
.slider-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

#view-more-images .modal-body {
  overflow-x: hidden;
}
.announcement {
  // position: absolute;
  // right: 1rem;
  // top: 4.45rem;
  // z-index: 10;
  // left: 0;
  // display: flex;
  // justify-content: end;
  // display:none;
}
.ri-close-line {
  position: absolute;
  top: 0;
  right: -1rem;
  color: #000;
  font-size: 2rem;
  margin-top: -30px;
  z-index: 20;
  cursor: pointer;
}
</style>
